import React from "react"
import ProductPage from "../components/ProductPage/productPage";
import obliczenia from '../images/gifs/pp_obliczenia.gif';
import { injectIntl, intlShape } from "react-intl";
import { compose } from "recompose";
import withPageContext from "../pageContext";


const data = (intl) => {
  return {
    title: intl.formatMessage({ id: 'obliczenia.title' }),
    desc: intl.formatMessage({ id: 'obliczenia.desc' }),
    anchors: ['', intl.formatMessage({ id: 'nav.produkty.obliczenia.strefy.slug' }), ''],
    sections: [
      {
        title: intl.formatMessage({ id: 'obliczenia.strefy.title' }),
        desc: intl.formatMessage({ id: 'obliczenia.strefy.desc' }),
        img: obliczenia,
        link: null,
        widget: "strefy",
        widgetVersion: 1,
        imageRight: true,
        background: "01_blue.png"
      }
      // {
      //   title: "Trasy",
      //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      //   img: "laptop.png",
      //   link: null,
      //   widget: "adresy",
      //   imageRight: false,
      //   background: "02_blue.png"
      // },
      // {
      //   title: "Sprawdzenia przestrzenne",
      //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      //   img: "laptop.png",
      //   link: "https://api.locit.io/docs/?urls.primaryName=phone-v2.2.0",
      //   target: 'blank',
      //   widget: null,
      //   imageRight: true,
      //   background: "03_blue.png"
      // }
    ],
    features: [
      {
        text: intl.formatMessage({ id: 'features.crm' }),
        icon: "bi_crm"
      },
      {
        text: intl.formatMessage({ id: 'features.analytics' }),
        icon: "analytics"
      },
      {
        text: intl.formatMessage({ id: 'features.ecommerce' }),
        icon: "ecommerce"
      }
    ]
  }
}

const ObliczeniaGeograficzne = (props) => (
  <ProductPage data={data(props.intl)} uri={props.uri} pathname={props.location.pathname} />
)

ObliczeniaGeograficzne.propTypes = {
  intl: intlShape.isRequired
};

export default compose(
  withPageContext,
  injectIntl
)(ObliczeniaGeograficzne)
